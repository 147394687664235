@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

body{
  font-family:"Roboto";
  width:100%;
  display : flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 50px; //Ajust the page's content which was incorrect because of the navbar
}
.container-fluid{
  // margin-top: 2em;
}
.content{
  flex : 1;
}

.well{
  border-radius: 2px;
}

.well h5{
  margin-top: 0px;
  margin-bottom: 15px;
}

h4 + hr{
  margin-top: -5px;
}

h3 + hr{
  margin-top: -5px;
}


h3{
  color : $btn-primary;
}


.form-control{
  border-radius: 2px;
}



.btn-installer{
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  .btn{
    height: 3em;
  }
}

textarea{
  max-width: 100%;
}

.fa-btn {
  margin-right: 6px;
}



.line {
    height: 1px;
    background-color: #D9D9DE;
    width: 90%;
    margin-left: 5%;
}

.line-full {
    height: 1px;
    background-color: #D9D9DE;
    width: 100%;
}

.or {
    display: inline-block;
    background-color: #ffffff;
    padding: 5px 10px;
    top: -16px;
    position: relative;
}

.lineor {
  text-align: center;
  margin-top: 2em;

}

.radio{
  margin-top: 0px;
}


.separation + .separation{
  border-left: 1px solid #D9D9DE;
}


.secondary{
  background-color: $SecondaryBackgroundColor;
  padding-bottom: 2em;
}

select{
  min-width: 4em;
}

.framwork{
  &.hidden{
    visibility: none;
  }
}
.no-style-list{
  padding-left: 10px;
  margin-bottom: 5em;
}

.no-style-list ul{
  padding-left: 20px;
  margin-bottom: 0.5em;
}

.no-style-list,.no-style-list li , .no-style-list ul{
  list-style: none

}
.no-style-list .clickable{
  cursor: pointer;
}

.no-style-list .panel .panel-body{
  padding-top: 0px ;
}

.btn-margin{
    margin-bottom: 1em;
}
