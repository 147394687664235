footer{
  border-top :1px dotted grey;
  background-color : $backgroundColorFooter;
  width:100%;
  margin-bottom: 0px !important;
  color: $colorFooter;
}

footer img{
  max-width: 100%;
}
