@import "variables";

// Base Class Definition
// -------------------------

.#{$md-css-prefix}, .material-icons {
    font: normal normal normal #{$md-font-size-base}/1 $md-font-family; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    display: inline-block;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    word-wrap: normal;
    top: 1px;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); // ensures no half-pixel rendering in firefox
}

.material-icons {
    width: 1em;
    height: 1em;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for IE. */
    font-feature-settings: 'liga';
}