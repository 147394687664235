// #### Nav pill / Menu ##############################
.nav-pills li a{

border-radius: 0px;
-webkit-transform: translateZ(0);
transform: translateZ(0);
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
-moz-osx-font-smoothing: grayscale;
overflow: hidden;
-webkit-transition-duration: 0.3s;
transition-duration: 0.3s;
-webkit-transition-property: color, background-color;
transition-property: color, background-color;
}

.nav-pills li a:hover{
background-color: lighten($themecolor, 40%);
}

.nav-pills .active a{
  background-color: $themecolor !important;

}

.nav-pills li a:active ,.nav-pills li a:focus{
  color : $pillslinkfocus;
  background-color: $themecolor !important;
}


.nav-pills .no-memory a:active, .nav-pills .no-memory a:focus{
  color : $pillslink;
  background-color: white !important;
}

.nav-pills{
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  padding-top: 2em;
  padding-bottom: 2em;
  border-radius: 2px;
}

// #### btn color , btn aspect , btn group ##############################

.btn-primary ,.btn-danger,.btn-warning ,.btn-success,.btn-info{
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: none;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.btn-default{
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.btn-primary{
  background-color: $btn-primary;
}
.btn-danger{
  background-color: $btn-danger;
}
.btn-warning{
  background-color: $btn-warning;
}
.btn-success{
  background-color: $btn-success;
}
.btn-default:hover, .btn-default:focus,.btn-default:active{
}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active{
  background-color: darken($btn-primary, 10%);
  border: none;
}
.btn-danger:hover,.btn-danger:focus,.btn-danger:active{
  background-color: darken($btn-danger, 10%);
}
.btn-warning:hover,.btn-warning:focus,.btn-warning:active{
  background-color: darken($btn-warning, 10%);
}
.btn-success:hover,.btn-success:focus,.btn-success:active{
  background-color: darken($btn-success, 10%);
}


.btn-group > .btn-primary ,.btn-group > .btn-danger,.btn-group > .btn-warning ,.btn-group > .btn-success,.btn-group > .btn-default{
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.26);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.input-group-btn > .btn-primary ,.input-group-btn > .btn-danger,.input-group-btn > .btn-warning ,.input-group-btn > .btn-success,.input-group-btn > .btn-default{
  border-radius: 2px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.26);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.btn-group{
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

}


.btn-circle{
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 13px 13px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circle:active {
  border: none;
}

label + .checkbox{
  margin-top: 0px;
}


.img-thumbnail {
  border-radius: 2px;
  padding: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}


.panel{
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

ol{
  border-radius: 0px !important;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.175);
  background-color: white !important;
}

.pagination .active span{
  background-color: $btn-primary;
  border-color: $btn-primary;
}

.pagination .active span:hover{
  background-color: $btn-primary;
  border-color: $btn-primary;
}

.pagination li a{
  color : $btn-primary;
}
.pagination li a:hover{
  color : $btn-primary;
}
