// Variables
// --------------------------

$md-font-family:      "Material Design Icons" !default;
$md-font-path:        "../fonts/materia" !default;
$md-font-size-base:   14px !default;
$md-css-prefix:       mdi !default;
$md-border-color:     #eee !default;
$md-inverse:          #fff !default;
$md-li-width:         (30em / 14) !default;
$md-version:          "2.2.1" !default;
