.inves_container h3{
  text-align: center;
}

.question-investigation + .question-investigation{
  margin-top: 1em;
}
.progress-bar-primary{
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;  
}
