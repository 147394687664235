
header img{
  max-height : 50em;

}

.owl-carousel{
  border-bottom: 1px solid #e3e3e3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    max-height: 50%;
}

.owl-carousel a{
  position: relative;
}

.owl-carousel a h4{
  color: #9C27B0;
  position: fixed;
  top : 0px;
  margin-left: 1em;
  margin-top: 1em;
  background-color: rgba(255,255,255,0.80);
  border: 1px solid rgba(0,0,0,0.10);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 2px;
  padding: 0.5em 1em;
}
