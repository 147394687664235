.dash_container .dash_content  .tab-content{
  background-color: white;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  padding-bottom: 2em;
  @media (max-width: $screen-sm-max) {
    margin-top: 2em;
  }


}

.dash_container .dash_content h3{
  text-align: center;
}

.not_center{
  text-align: left !important;
}


.dash_content .tab-content .btn-installer{
  margin-bottom: 0em;

}

.dash_container{
  margin-top: 2em;
  margin-bottom: 2em;
}

.dash_container .dash_menu{
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: $screen-sm-min) {
    width: 100%;
    float: left;
  }
  @media (min-width: $screen-md-min) {
    width: 25%;
    float: left;
  }
  @media (min-width: $screen-lg-min) {
    width: 16.6666666667%;
    float: left;
  }
}

.dash_container .dash_menu .nav-pills{
  border: none;
  @media (min-width: $screen-md-min) {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.dash_container{
  .dash_content{
    padding-left: 15px;
    padding-right: 15px;
  }
  .dash_menu + .dash_content{
  position: relative;
  min-height: 1px;

  @media (min-width: $screen-sm-min) {
    width: 100%;
    float: left;
  }
  @media (min-width: $screen-md-min) {
    width: 75%;
    float: left;
  }
  @media (min-width: $screen-lg-min) {
    width: 83.3333333333%;
    float: left;
  }
}}

.dash_container .dash_menu .nav-pills{
  background-color: white;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  margin-bottom: 2em;
}
