//theme color
$themecolor : #9C27B0;
//Navigation Bar
$backgroundColorNavBar : $themecolor;
$colorNavBar : white; // color of text
$underlinedColorNavBarHover : black; // underlined color of hover item in navbar
$underlinedColorNavBarActive : black; // underlined color of active item in navbar


$backgroundColorNavBarHover : lighten($backgroundColorNavBar, 10%); // underlined color of hover item in navbar for mobile
$backgroundColorNavBarActive : lighten($backgroundColorNavBar, 10%); // underlined color of active item in navbar for mobile

//link
$link : #337ab7;
$linkhover : #23527c;
$linkfocus : #23527c;
$linkactive : white;
$linkvisited : #337ab7;


//nav-pills
$pillslink : #337ab7;
$pillslinkhover : #23527c;
$pillslinkfocus : white;
$pillslinkactive : white;
$pillslinkvisited : #337ab7;


//footer
$backgroundColorFooter : $themecolor;
$colorFooter : white; // color of text
$colorLinkFooter : white; // color of link


//body
$backgroundColor : #FAFAFA;
$SecondaryBackgroundColor : #f5f5f5;
$titleColor : $themecolor;
$textColor : #7A7A7A;



//button
$btn-primary : $themecolor;
$btn-warning : #FF9100;
$btn-success : #76FF03;
$btn-danger  : #FF1744;
