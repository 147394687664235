// backgroundColor of navbar
.navbar{
  background-color: $backgroundColorNavBar;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 0px;
}
// text color of item in navbar
.navbar a{
  color: $colorNavBar;
}

//#####################################################  Menu Item for annimation
.navbar .navbar-nav li a {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}


//##################################################### DropDown Menu
.navbar .open > a , .nav .open > a:focus ,.nav .open > a:hover{
  background-color: $backgroundColorNavBarActive ;
  color: $colorNavBar;
}


.navbar > li > a:hover, .nav > li > a:focus{//do not remove
  background-color: $backgroundColorNavBar ;
}

.navbar .open .dropdown-menu { //posision and color of dropdown menu
  margin-top:  0.5em;
  background-color: $backgroundColorNavBar;
  border: none;
  border-radius: 2px;
}

.navbar .open .dropdown-menu li a { //Color of text in dropdown menu
  color : $colorNavBar;
}


//##################################################### hovered item in menu and dropdown menu

.navbar .navbar-nav li a:hover{
  background-color:  $backgroundColorNavBarHover;
}



//##################################################### Selected item in menu
// PC and Tab -> underline
@media (min-width: $screen-sm-min) {
  .navbar .navbar-nav > .active a{
    border-bottom: 0.25em solid $underlinedColorNavBarActive;
    margin-bottom: -0.25em;
    padding-bottom: 0.85em;
  }
}

// mobile -> backgroundColor
@media (max-width: $screen-sm-min) {
  .navbar .navbar-nav > .active a{
    background-color:  $backgroundColorNavBarActive;

  }
}


//##################################################### color and animation of menu selector for mobile
.navbar .navbar-toggle .icon-bar {
  background-color: $backgroundColor;
}

.navbar .navbar-toggle {
  border: none;
  background: transparent !important;

  &:hover {
    background: transparent !important;
  }

  .icon-bar {
    width: 22px;
    transition: all 0.2s;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }

  &.collapsed {
    .top-bar {
      transform: rotate(0);
    }
    .middle-bar {
      opacity: 1;
    }
    .bottom-bar {
      transform: rotate(0);
    }
  }
}
