.project{
  padding-top : 0;
  padding-right : 0;
  padding-left : 0;

  text-align: justify;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.project img{
  width : 100%;
  border-top-right-radius : 0.2em;
  border-top-left-radius : 0.2em;
  height: 100%;
}
.project h4{
  font-weight: bold;
  color: $titleColor;
}

.project .contentproject{
  padding-left : 1em;
  max-height: 12em;
  min-height: 12em;
  padding-right : 1em;
}
