.cropper-div{
  position:relative;
}

.cropper{
  min-height: 3.1em;
}

.cropper-action{
  width: 100%;
  height:3.1em;
  background-color:black;
}
.cropper-container{
  border: 1px solid #555555;
}

.cropper-crop-box{
  padding-bottom: 2px;
  padding-right: 2px;
}

.cropper-action .pull-right{
margin-right: 1em;
padding-top: 0.4em;

}

.cropper-action input{
  padding-top: 0.7em;
color : white;
@media (max-width: $screen-xs-max) {
  color : transparent;

}
}
