.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.bs-callout-no-box {
  border-left: 1px solid #eee;
  padding: 1px 10px;
  border-left-width: 2px;
}
.bs-callout-no-box .well {
  margin-bottom: 0px;
}
.bs-callout h4 ,.bs-callout-no-box h4{
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout hr, .bs-callout-no-box hr {
  margin-top: 0px;
  margin-bottom: 1em;
}
.bs-callout-content{
  margin-bottom: 1em;
}
.bs-callout p:last-child, .bs-callout-no-box p:last-child{
  margin-bottom: 0;
}
.bs-callout code ,.bs-callout-no-box code{
  border-radius: 3px;
}
.bs-callout+.bs-callout, .bs-callout-no-box+.bs-callout-no-box {
  margin-top: 30px;
}
.bs-callout-default {
  border-left-color: #777;
}
.bs-callout-default h4 {
  color: #777;
}
.bs-callout-primary {
  border-left-color: $btn-primary;
}
.bs-callout-primary h4 {
  color: $btn-primary;
}
.bs-callout-success {
  border-left-color: $btn-success;
}
.bs-callout-success h4 {
  color: $btn-success;
}
.bs-callout-danger {
  border-left-color: $btn-danger;
}
.bs-callout-danger h4 {
  color: $btn-danger;
}
.bs-callout-warning {
  border-left-color: $btn-warning;
}
.bs-callout-warning h4 {
  color: $btn-warning;
}
.bs-callout-info {
  border-left-color: #5bc0de;
}
.bs-callout-info h4 {
  color: #5bc0de;
}


// #### Button Circle ##############################

.full-circle-lg {
  height:3em;
  width:3em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  -webkit-border-radius:75px;
  -moz-border-radius:75px;
  border-radius: 75px;
  border: 1px solid rgba(0, 0, 0, 0.26);
}
.full-circle-sm {
  display: inline-block;
  height:0.8em;
  width:0.8em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  -webkit-border-radius:75px;
  -moz-border-radius:75px;
  border-radius: 75px;
  border: 1px solid rgba(0, 0, 0, 0.26);
}


// #### Progress Bar Color ##############################
.progress{
  border-radius: 2px;
}

.progress-bar-primary{
  background-color: $btn-primary;
}


// #### Nav bar arrow ##############################
.nav-arrow li{
  padding-left: 0px;
  padding-right: 0px;
}
.nav-arrow ul > li + li {
  margin-left: 0px;
}
.nav-arrow li:not(:last-of-type) a:after{
  background: white;
  content: '';
  top : 0px;
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 1;
  right: -20px;
  -moz-border-radius: 0 2px 0 500px;
  -webkit-border-radius: 0;
  border-radius: 0 2px 0 50px;
  -moz-transform: scale(0.707) rotate(45deg);
  -ms-transform: scale(0.707) rotate(45deg);
  -webkit-transform: scale(0.707) rotate(45deg);
  transform: scale(0.707) rotate(45deg);
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.nav-arrow .disabled a:after{
  content: none !important;
}

.nav-arrow li:not(:last-of-type) a:hover:after{
  background: #eeeeee;
}

.nav-arrow .active:not(:last-of-type) a:after ,.nav-arrow .active:not(:last-of-type) a:hover:after,.nav-arrow .active:not(:last-of-type) a:focus:after,.nav-arrow li:not(:last-of-type) a:focus:after{
  background: $backgroundColorNavBar;
}

.nav-arrow li:not(:first-of-type) a{
  padding-left: 20px;
}
.nav-arrow li:not(:last-of-type) a{
  padding-right: 0px;
}
.nav-arrow li a {
  background: white;
  white-space: nowrap;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}
.nav-arrow .active a,.nav-arrow .active a:hover,.nav-arrow li a:focus {
  color : white;
  background-color: $backgroundColorNavBar;
}
@media (max-width: $screen-xs-max){
  .nav-arrow li a span{
    display: none;

  }
  .nav-arrow li a:hover span{
    display: initial;
  }
}



// Bar
.bar{
  z-index: 1;
  background-color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.bar-bottom{
  z-index: 1;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: white;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
}
.bar  .progress{
  margin-bottom: 10px;
  margin-top: 10px;
}
.bar + *{
  margin-top: 40px !important;
}

//fixed top
.fixed-top{
  position: fixed;
  right: 0;
  left: 0;
  top: 50;
  z-index: 2;
  border-width: 0 0 1px;
}

.fixed-bottom{
  &.fixed{
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0px;
    z-index: 1;
    border-width: 0 0 1px;
    margin-bottom: 0em ;

  }
  &.initial{
    position: initial;
    margin-bottom: 0em;
  }
}

.fixed-middle{
  &.initial{
    position: initial;
  }
  &.fixed{
    @media (min-width: $screen-md-min){
      position: fixed;
      width: 212.5px;
      top : calc(50px + 2em);
    }
    @media (min-width: $screen-lg-min){
      position: fixed;
      width: 262.5px;
      top : calc(50px + 2em);

    }
  }
}

.fixed-middle-percent{
  &.initial{
    position: initial;
  }
  &.fixed{
    @media (min-width: $screen-md-min){
      position: fixed;
      width: calc(25% - 35px);
      top : calc(50px + 2em);
    }
    @media (min-width: $screen-lg-min){
      position: fixed;
      width: calc(16.6666666667% - 35px);
      top : calc(50px + 2em);
    }
  }
}




// col same height

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.vertical-middle{
  margin: auto;
}


.sub-container{
  margin-top: 1.5em;
}
.sub-container-fluid{
  margin-top: 1.5em;
}


.img-full-responsive{
  height: auto;
  display: block;
  width: 100%;
}


.no-margin-bottom{
  margin-bottom: 0em !important;
}

.no-margin-top{
  margin-top: 0em !important;
}

.top-buffer{
  margin-top: 2em;
}





  .table-scroll{
  overflow-y: scroll;
  height: 20em;
  padding: 0px;

}
