@import "variables";

// Icon Sizes
// -------------------------

/* makes the font 33% larger relative to the icon container */
.#{$md-css-prefix}-lg, .#{$md-css-prefix}-size-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}
.#{$md-css-prefix}-2x, .#{$md-css-prefix}-size-2x { font-size: 2em; }
.#{$md-css-prefix}-3x, .#{$md-css-prefix}-size-3x { font-size: 3em; }
.#{$md-css-prefix}-4x, .#{$md-css-prefix}-size-4x { font-size: 4em; }
.#{$md-css-prefix}-5x, .#{$md-css-prefix}-size-5x { font-size: 5em; }
