@import "variables";

.#{$md-css-prefix}-3d-rotation:before { content: "\e84d"; }
.#{$md-css-prefix}-ac-unit:before { content: "\eb3b"; }
.#{$md-css-prefix}-access-alarm:before { content: "\e190"; }
.#{$md-css-prefix}-access-alarms:before { content: "\e191"; }
.#{$md-css-prefix}-access-time:before { content: "\e192"; }
.#{$md-css-prefix}-accessibility:before { content: "\e84e"; }
.#{$md-css-prefix}-accessible:before { content: "\e914"; }
.#{$md-css-prefix}-account-balance:before { content: "\e84f"; }
.#{$md-css-prefix}-account-balance-wallet:before { content: "\e850"; }
.#{$md-css-prefix}-account-box:before { content: "\e851"; }
.#{$md-css-prefix}-account-circle:before { content: "\e853"; }
.#{$md-css-prefix}-adb:before { content: "\e60e"; }
.#{$md-css-prefix}-add:before { content: "\e145"; }
.#{$md-css-prefix}-add-a-photo:before { content: "\e439"; }
.#{$md-css-prefix}-add-alarm:before { content: "\e193"; }
.#{$md-css-prefix}-add-alert:before { content: "\e003"; }
.#{$md-css-prefix}-add-box:before { content: "\e146"; }
.#{$md-css-prefix}-add-circle:before { content: "\e147"; }
.#{$md-css-prefix}-add-circle-outline:before { content: "\e148"; }
.#{$md-css-prefix}-add-location:before { content: "\e567"; }
.#{$md-css-prefix}-add-shopping-cart:before { content: "\e854"; }
.#{$md-css-prefix}-add-to-photos:before { content: "\e39d"; }
.#{$md-css-prefix}-add-to-queue:before { content: "\e05c"; }
.#{$md-css-prefix}-adjust:before { content: "\e39e"; }
.#{$md-css-prefix}-airline-seat-flat:before { content: "\e630"; }
.#{$md-css-prefix}-airline-seat-flat-angled:before { content: "\e631"; }
.#{$md-css-prefix}-airline-seat-individual-suite:before { content: "\e632"; }
.#{$md-css-prefix}-airline-seat-legroom-extra:before { content: "\e633"; }
.#{$md-css-prefix}-airline-seat-legroom-normal:before { content: "\e634"; }
.#{$md-css-prefix}-airline-seat-legroom-reduced:before { content: "\e635"; }
.#{$md-css-prefix}-airline-seat-recline-extra:before { content: "\e636"; }
.#{$md-css-prefix}-airline-seat-recline-normal:before { content: "\e637"; }
.#{$md-css-prefix}-airplanemode-active:before { content: "\e195"; }
.#{$md-css-prefix}-airplanemode-inactive:before { content: "\e194"; }
.#{$md-css-prefix}-airplay:before { content: "\e055"; }
.#{$md-css-prefix}-airport-shuttle:before { content: "\eb3c"; }
.#{$md-css-prefix}-alarm:before { content: "\e855"; }
.#{$md-css-prefix}-alarm-add:before { content: "\e856"; }
.#{$md-css-prefix}-alarm-off:before { content: "\e857"; }
.#{$md-css-prefix}-alarm-on:before { content: "\e858"; }
.#{$md-css-prefix}-album:before { content: "\e019"; }
.#{$md-css-prefix}-all-inclusive:before { content: "\eb3d"; }
.#{$md-css-prefix}-all-out:before { content: "\e90b"; }
.#{$md-css-prefix}-android:before { content: "\e859"; }
.#{$md-css-prefix}-announcement:before { content: "\e85a"; }
.#{$md-css-prefix}-apps:before { content: "\e5c3"; }
.#{$md-css-prefix}-archive:before { content: "\e149"; }
.#{$md-css-prefix}-arrow-back:before { content: "\e5c4"; }
.#{$md-css-prefix}-arrow-downward:before { content: "\e5db"; }
.#{$md-css-prefix}-arrow-drop-down:before { content: "\e5c5"; }
.#{$md-css-prefix}-arrow-drop-down-circle:before { content: "\e5c6"; }
.#{$md-css-prefix}-arrow-drop-up:before { content: "\e5c7"; }
.#{$md-css-prefix}-arrow-forward:before { content: "\e5c8"; }
.#{$md-css-prefix}-arrow-upward:before { content: "\e5d8"; }
.#{$md-css-prefix}-art-track:before { content: "\e060"; }
.#{$md-css-prefix}-aspect-ratio:before { content: "\e85b"; }
.#{$md-css-prefix}-assessment:before { content: "\e85c"; }
.#{$md-css-prefix}-assignment:before { content: "\e85d"; }
.#{$md-css-prefix}-assignment-ind:before { content: "\e85e"; }
.#{$md-css-prefix}-assignment-late:before { content: "\e85f"; }
.#{$md-css-prefix}-assignment-return:before { content: "\e860"; }
.#{$md-css-prefix}-assignment-returned:before { content: "\e861"; }
.#{$md-css-prefix}-assignment-turned-in:before { content: "\e862"; }
.#{$md-css-prefix}-assistant:before { content: "\e39f"; }
.#{$md-css-prefix}-assistant-photo:before { content: "\e3a0"; }
.#{$md-css-prefix}-attach-file:before { content: "\e226"; }
.#{$md-css-prefix}-attach-money:before { content: "\e227"; }
.#{$md-css-prefix}-attachment:before { content: "\e2bc"; }
.#{$md-css-prefix}-audiotrack:before { content: "\e3a1"; }
.#{$md-css-prefix}-autorenew:before { content: "\e863"; }
.#{$md-css-prefix}-av-timer:before { content: "\e01b"; }
.#{$md-css-prefix}-backspace:before { content: "\e14a"; }
.#{$md-css-prefix}-backup:before { content: "\e864"; }
.#{$md-css-prefix}-battery-alert:before { content: "\e19c"; }
.#{$md-css-prefix}-battery-charging-full:before { content: "\e1a3"; }
.#{$md-css-prefix}-battery-full:before { content: "\e1a4"; }
.#{$md-css-prefix}-battery-std:before { content: "\e1a5"; }
.#{$md-css-prefix}-battery-unknown:before { content: "\e1a6"; }
.#{$md-css-prefix}-beach-access:before { content: "\eb3e"; }
.#{$md-css-prefix}-beenhere:before { content: "\e52d"; }
.#{$md-css-prefix}-block:before { content: "\e14b"; }
.#{$md-css-prefix}-bluetooth:before { content: "\e1a7"; }
.#{$md-css-prefix}-bluetooth-audio:before { content: "\e60f"; }
.#{$md-css-prefix}-bluetooth-connected:before { content: "\e1a8"; }
.#{$md-css-prefix}-bluetooth-disabled:before { content: "\e1a9"; }
.#{$md-css-prefix}-bluetooth-searching:before { content: "\e1aa"; }
.#{$md-css-prefix}-blur-circular:before { content: "\e3a2"; }
.#{$md-css-prefix}-blur-linear:before { content: "\e3a3"; }
.#{$md-css-prefix}-blur-off:before { content: "\e3a4"; }
.#{$md-css-prefix}-blur-on:before { content: "\e3a5"; }
.#{$md-css-prefix}-book:before { content: "\e865"; }
.#{$md-css-prefix}-bookmark:before { content: "\e866"; }
.#{$md-css-prefix}-bookmark-border:before { content: "\e867"; }
.#{$md-css-prefix}-border-all:before { content: "\e228"; }
.#{$md-css-prefix}-border-bottom:before { content: "\e229"; }
.#{$md-css-prefix}-border-clear:before { content: "\e22a"; }
.#{$md-css-prefix}-border-color:before { content: "\e22b"; }
.#{$md-css-prefix}-border-horizontal:before { content: "\e22c"; }
.#{$md-css-prefix}-border-inner:before { content: "\e22d"; }
.#{$md-css-prefix}-border-left:before { content: "\e22e"; }
.#{$md-css-prefix}-border-outer:before { content: "\e22f"; }
.#{$md-css-prefix}-border-right:before { content: "\e230"; }
.#{$md-css-prefix}-border-style:before { content: "\e231"; }
.#{$md-css-prefix}-border-top:before { content: "\e232"; }
.#{$md-css-prefix}-border-vertical:before { content: "\e233"; }
.#{$md-css-prefix}-branding-watermark:before { content: "\e06b"; }
.#{$md-css-prefix}-brightness-1:before { content: "\e3a6"; }
.#{$md-css-prefix}-brightness-2:before { content: "\e3a7"; }
.#{$md-css-prefix}-brightness-3:before { content: "\e3a8"; }
.#{$md-css-prefix}-brightness-4:before { content: "\e3a9"; }
.#{$md-css-prefix}-brightness-5:before { content: "\e3aa"; }
.#{$md-css-prefix}-brightness-6:before { content: "\e3ab"; }
.#{$md-css-prefix}-brightness-7:before { content: "\e3ac"; }
.#{$md-css-prefix}-brightness-auto:before { content: "\e1ab"; }
.#{$md-css-prefix}-brightness-high:before { content: "\e1ac"; }
.#{$md-css-prefix}-brightness-low:before { content: "\e1ad"; }
.#{$md-css-prefix}-brightness-medium:before { content: "\e1ae"; }
.#{$md-css-prefix}-broken-image:before { content: "\e3ad"; }
.#{$md-css-prefix}-brush:before { content: "\e3ae"; }
.#{$md-css-prefix}-bubble-chart:before { content: "\e6dd"; }
.#{$md-css-prefix}-bug-report:before { content: "\e868"; }
.#{$md-css-prefix}-build:before { content: "\e869"; }
.#{$md-css-prefix}-burst-mode:before { content: "\e43c"; }
.#{$md-css-prefix}-business:before { content: "\e0af"; }
.#{$md-css-prefix}-business-center:before { content: "\eb3f"; }
.#{$md-css-prefix}-cached:before { content: "\e86a"; }
.#{$md-css-prefix}-cake:before { content: "\e7e9"; }
.#{$md-css-prefix}-call:before { content: "\e0b0"; }
.#{$md-css-prefix}-call-end:before { content: "\e0b1"; }
.#{$md-css-prefix}-call-made:before { content: "\e0b2"; }
.#{$md-css-prefix}-call-merge:before { content: "\e0b3"; }
.#{$md-css-prefix}-call-missed:before { content: "\e0b4"; }
.#{$md-css-prefix}-call-missed-outgoing:before { content: "\e0e4"; }
.#{$md-css-prefix}-call-received:before { content: "\e0b5"; }
.#{$md-css-prefix}-call-split:before { content: "\e0b6"; }
.#{$md-css-prefix}-call-to-action:before { content: "\e06c"; }
.#{$md-css-prefix}-camera:before { content: "\e3af"; }
.#{$md-css-prefix}-camera-alt:before { content: "\e3b0"; }
.#{$md-css-prefix}-camera-enhance:before { content: "\e8fc"; }
.#{$md-css-prefix}-camera-front:before { content: "\e3b1"; }
.#{$md-css-prefix}-camera-rear:before { content: "\e3b2"; }
.#{$md-css-prefix}-camera-roll:before { content: "\e3b3"; }
.#{$md-css-prefix}-cancel:before { content: "\e5c9"; }
.#{$md-css-prefix}-card-giftcard:before { content: "\e8f6"; }
.#{$md-css-prefix}-card-membership:before { content: "\e8f7"; }
.#{$md-css-prefix}-card-travel:before { content: "\e8f8"; }
.#{$md-css-prefix}-casino:before { content: "\eb40"; }
.#{$md-css-prefix}-cast:before { content: "\e307"; }
.#{$md-css-prefix}-cast-connected:before { content: "\e308"; }
.#{$md-css-prefix}-center-focus-strong:before { content: "\e3b4"; }
.#{$md-css-prefix}-center-focus-weak:before { content: "\e3b5"; }
.#{$md-css-prefix}-change-history:before { content: "\e86b"; }
.#{$md-css-prefix}-chat:before { content: "\e0b7"; }
.#{$md-css-prefix}-chat-bubble:before { content: "\e0ca"; }
.#{$md-css-prefix}-chat-bubble-outline:before { content: "\e0cb"; }
.#{$md-css-prefix}-check:before { content: "\e5ca"; }
.#{$md-css-prefix}-check-box:before { content: "\e834"; }
.#{$md-css-prefix}-check-box-outline-blank:before { content: "\e835"; }
.#{$md-css-prefix}-check-circle:before { content: "\e86c"; }
.#{$md-css-prefix}-chevron-left:before { content: "\e5cb"; }
.#{$md-css-prefix}-chevron-right:before { content: "\e5cc"; }
.#{$md-css-prefix}-child-care:before { content: "\eb41"; }
.#{$md-css-prefix}-child-friendly:before { content: "\eb42"; }
.#{$md-css-prefix}-chrome-reader-mode:before { content: "\e86d"; }
.#{$md-css-prefix}-class:before { content: "\e86e"; }
.#{$md-css-prefix}-clear:before { content: "\e14c"; }
.#{$md-css-prefix}-clear-all:before { content: "\e0b8"; }
.#{$md-css-prefix}-close:before { content: "\e5cd"; }
.#{$md-css-prefix}-closed-caption:before { content: "\e01c"; }
.#{$md-css-prefix}-cloud:before { content: "\e2bd"; }
.#{$md-css-prefix}-cloud-circle:before { content: "\e2be"; }
.#{$md-css-prefix}-cloud-done:before { content: "\e2bf"; }
.#{$md-css-prefix}-cloud-download:before { content: "\e2c0"; }
.#{$md-css-prefix}-cloud-off:before { content: "\e2c1"; }
.#{$md-css-prefix}-cloud-queue:before { content: "\e2c2"; }
.#{$md-css-prefix}-cloud-upload:before { content: "\e2c3"; }
.#{$md-css-prefix}-code:before { content: "\e86f"; }
.#{$md-css-prefix}-collections:before { content: "\e3b6"; }
.#{$md-css-prefix}-collections-bookmark:before { content: "\e431"; }
.#{$md-css-prefix}-color-lens:before { content: "\e3b7"; }
.#{$md-css-prefix}-colorize:before { content: "\e3b8"; }
.#{$md-css-prefix}-comment:before { content: "\e0b9"; }
.#{$md-css-prefix}-compare:before { content: "\e3b9"; }
.#{$md-css-prefix}-compare-arrows:before { content: "\e915"; }
.#{$md-css-prefix}-computer:before { content: "\e30a"; }
.#{$md-css-prefix}-confirmation-number:before { content: "\e638"; }
.#{$md-css-prefix}-contact-mail:before { content: "\e0d0"; }
.#{$md-css-prefix}-contact-phone:before { content: "\e0cf"; }
.#{$md-css-prefix}-contacts:before { content: "\e0ba"; }
.#{$md-css-prefix}-content-copy:before { content: "\e14d"; }
.#{$md-css-prefix}-content-cut:before { content: "\e14e"; }
.#{$md-css-prefix}-content-paste:before { content: "\e14f"; }
.#{$md-css-prefix}-control-point:before { content: "\e3ba"; }
.#{$md-css-prefix}-control-point-duplicate:before { content: "\e3bb"; }
.#{$md-css-prefix}-copyright:before { content: "\e90c"; }
.#{$md-css-prefix}-create:before { content: "\e150"; }
.#{$md-css-prefix}-create-new-folder:before { content: "\e2cc"; }
.#{$md-css-prefix}-credit-card:before { content: "\e870"; }
.#{$md-css-prefix}-crop:before { content: "\e3be"; }
.#{$md-css-prefix}-crop-16-9:before { content: "\e3bc"; }
.#{$md-css-prefix}-crop-3-2:before { content: "\e3bd"; }
.#{$md-css-prefix}-crop-5-4:before { content: "\e3bf"; }
.#{$md-css-prefix}-crop-7-5:before { content: "\e3c0"; }
.#{$md-css-prefix}-crop-din:before { content: "\e3c1"; }
.#{$md-css-prefix}-crop-free:before { content: "\e3c2"; }
.#{$md-css-prefix}-crop-landscape:before { content: "\e3c3"; }
.#{$md-css-prefix}-crop-original:before { content: "\e3c4"; }
.#{$md-css-prefix}-crop-portrait:before { content: "\e3c5"; }
.#{$md-css-prefix}-crop-rotate:before { content: "\e437"; }
.#{$md-css-prefix}-crop-square:before { content: "\e3c6"; }
.#{$md-css-prefix}-dashboard:before { content: "\e871"; }
.#{$md-css-prefix}-data-usage:before { content: "\e1af"; }
.#{$md-css-prefix}-date-range:before { content: "\e916"; }
.#{$md-css-prefix}-dehaze:before { content: "\e3c7"; }
.#{$md-css-prefix}-delete:before { content: "\e872"; }
.#{$md-css-prefix}-delete-forever:before { content: "\e92b"; }
.#{$md-css-prefix}-delete-sweep:before { content: "\e16c"; }
.#{$md-css-prefix}-description:before { content: "\e873"; }
.#{$md-css-prefix}-desktop-mac:before { content: "\e30b"; }
.#{$md-css-prefix}-desktop-windows:before { content: "\e30c"; }
.#{$md-css-prefix}-details:before { content: "\e3c8"; }
.#{$md-css-prefix}-developer-board:before { content: "\e30d"; }
.#{$md-css-prefix}-developer-mode:before { content: "\e1b0"; }
.#{$md-css-prefix}-device-hub:before { content: "\e335"; }
.#{$md-css-prefix}-devices:before { content: "\e1b1"; }
.#{$md-css-prefix}-devices-other:before { content: "\e337"; }
.#{$md-css-prefix}-dialer-sip:before { content: "\e0bb"; }
.#{$md-css-prefix}-dialpad:before { content: "\e0bc"; }
.#{$md-css-prefix}-directions:before { content: "\e52e"; }
.#{$md-css-prefix}-directions-bike:before { content: "\e52f"; }
.#{$md-css-prefix}-directions-boat:before { content: "\e532"; }
.#{$md-css-prefix}-directions-bus:before { content: "\e530"; }
.#{$md-css-prefix}-directions-car:before { content: "\e531"; }
.#{$md-css-prefix}-directions-railway:before { content: "\e534"; }
.#{$md-css-prefix}-directions-run:before { content: "\e566"; }
.#{$md-css-prefix}-directions-subway:before { content: "\e533"; }
.#{$md-css-prefix}-directions-transit:before { content: "\e535"; }
.#{$md-css-prefix}-directions-walk:before { content: "\e536"; }
.#{$md-css-prefix}-disc-full:before { content: "\e610"; }
.#{$md-css-prefix}-dns:before { content: "\e875"; }
.#{$md-css-prefix}-do-not-disturb:before { content: "\e612"; }
.#{$md-css-prefix}-do-not-disturb-alt:before { content: "\e611"; }
.#{$md-css-prefix}-do-not-disturb-off:before { content: "\e643"; }
.#{$md-css-prefix}-do-not-disturb-on:before { content: "\e644"; }
.#{$md-css-prefix}-dock:before { content: "\e30e"; }
.#{$md-css-prefix}-domain:before { content: "\e7ee"; }
.#{$md-css-prefix}-done:before { content: "\e876"; }
.#{$md-css-prefix}-done-all:before { content: "\e877"; }
.#{$md-css-prefix}-donut-large:before { content: "\e917"; }
.#{$md-css-prefix}-donut-small:before { content: "\e918"; }
.#{$md-css-prefix}-drafts:before { content: "\e151"; }
.#{$md-css-prefix}-drag-handle:before { content: "\e25d"; }
.#{$md-css-prefix}-drive-eta:before { content: "\e613"; }
.#{$md-css-prefix}-dvr:before { content: "\e1b2"; }
.#{$md-css-prefix}-edit:before { content: "\e3c9"; }
.#{$md-css-prefix}-edit-location:before { content: "\e568"; }
.#{$md-css-prefix}-eject:before { content: "\e8fb"; }
.#{$md-css-prefix}-email:before { content: "\e0be"; }
.#{$md-css-prefix}-enhanced-encryption:before { content: "\e63f"; }
.#{$md-css-prefix}-equalizer:before { content: "\e01d"; }
.#{$md-css-prefix}-error:before { content: "\e000"; }
.#{$md-css-prefix}-error-outline:before { content: "\e001"; }
.#{$md-css-prefix}-euro-symbol:before { content: "\e926"; }
.#{$md-css-prefix}-ev-station:before { content: "\e56d"; }
.#{$md-css-prefix}-event:before { content: "\e878"; }
.#{$md-css-prefix}-event-available:before { content: "\e614"; }
.#{$md-css-prefix}-event-busy:before { content: "\e615"; }
.#{$md-css-prefix}-event-note:before { content: "\e616"; }
.#{$md-css-prefix}-event-seat:before { content: "\e903"; }
.#{$md-css-prefix}-exit-to-app:before { content: "\e879"; }
.#{$md-css-prefix}-expand-less:before { content: "\e5ce"; }
.#{$md-css-prefix}-expand-more:before { content: "\e5cf"; }
.#{$md-css-prefix}-explicit:before { content: "\e01e"; }
.#{$md-css-prefix}-explore:before { content: "\e87a"; }
.#{$md-css-prefix}-exposure:before { content: "\e3ca"; }
.#{$md-css-prefix}-exposure-neg-1:before { content: "\e3cb"; }
.#{$md-css-prefix}-exposure-neg-2:before { content: "\e3cc"; }
.#{$md-css-prefix}-exposure-plus-1:before { content: "\e3cd"; }
.#{$md-css-prefix}-exposure-plus-2:before { content: "\e3ce"; }
.#{$md-css-prefix}-exposure-zero:before { content: "\e3cf"; }
.#{$md-css-prefix}-extension:before { content: "\e87b"; }
.#{$md-css-prefix}-face:before { content: "\e87c"; }
.#{$md-css-prefix}-fast-forward:before { content: "\e01f"; }
.#{$md-css-prefix}-fast-rewind:before { content: "\e020"; }
.#{$md-css-prefix}-favorite:before { content: "\e87d"; }
.#{$md-css-prefix}-favorite-border:before { content: "\e87e"; }
.#{$md-css-prefix}-featured-play-list:before { content: "\e06d"; }
.#{$md-css-prefix}-featured-video:before { content: "\e06e"; }
.#{$md-css-prefix}-feedback:before { content: "\e87f"; }
.#{$md-css-prefix}-fiber-dvr:before { content: "\e05d"; }
.#{$md-css-prefix}-fiber-manual-record:before { content: "\e061"; }
.#{$md-css-prefix}-fiber-new:before { content: "\e05e"; }
.#{$md-css-prefix}-fiber-pin:before { content: "\e06a"; }
.#{$md-css-prefix}-fiber-smart-record:before { content: "\e062"; }
.#{$md-css-prefix}-file-download:before { content: "\e2c4"; }
.#{$md-css-prefix}-file-upload:before { content: "\e2c6"; }
.#{$md-css-prefix}-filter:before { content: "\e3d3"; }
.#{$md-css-prefix}-filter-1:before { content: "\e3d0"; }
.#{$md-css-prefix}-filter-2:before { content: "\e3d1"; }
.#{$md-css-prefix}-filter-3:before { content: "\e3d2"; }
.#{$md-css-prefix}-filter-4:before { content: "\e3d4"; }
.#{$md-css-prefix}-filter-5:before { content: "\e3d5"; }
.#{$md-css-prefix}-filter-6:before { content: "\e3d6"; }
.#{$md-css-prefix}-filter-7:before { content: "\e3d7"; }
.#{$md-css-prefix}-filter-8:before { content: "\e3d8"; }
.#{$md-css-prefix}-filter-9:before { content: "\e3d9"; }
.#{$md-css-prefix}-filter-9-plus:before { content: "\e3da"; }
.#{$md-css-prefix}-filter-b-and-w:before { content: "\e3db"; }
.#{$md-css-prefix}-filter-center-focus:before { content: "\e3dc"; }
.#{$md-css-prefix}-filter-drama:before { content: "\e3dd"; }
.#{$md-css-prefix}-filter-frames:before { content: "\e3de"; }
.#{$md-css-prefix}-filter-hdr:before { content: "\e3df"; }
.#{$md-css-prefix}-filter-list:before { content: "\e152"; }
.#{$md-css-prefix}-filter-none:before { content: "\e3e0"; }
.#{$md-css-prefix}-filter-tilt-shift:before { content: "\e3e2"; }
.#{$md-css-prefix}-filter-vintage:before { content: "\e3e3"; }
.#{$md-css-prefix}-find-in-page:before { content: "\e880"; }
.#{$md-css-prefix}-find-replace:before { content: "\e881"; }
.#{$md-css-prefix}-fingerprint:before { content: "\e90d"; }
.#{$md-css-prefix}-first-page:before { content: "\e5dc"; }
.#{$md-css-prefix}-fitness-center:before { content: "\eb43"; }
.#{$md-css-prefix}-flag:before { content: "\e153"; }
.#{$md-css-prefix}-flare:before { content: "\e3e4"; }
.#{$md-css-prefix}-flash-auto:before { content: "\e3e5"; }
.#{$md-css-prefix}-flash-off:before { content: "\e3e6"; }
.#{$md-css-prefix}-flash-on:before { content: "\e3e7"; }
.#{$md-css-prefix}-flight:before { content: "\e539"; }
.#{$md-css-prefix}-flight-land:before { content: "\e904"; }
.#{$md-css-prefix}-flight-takeoff:before { content: "\e905"; }
.#{$md-css-prefix}-flip:before { content: "\e3e8"; }
.#{$md-css-prefix}-flip-to-back:before { content: "\e882"; }
.#{$md-css-prefix}-flip-to-front:before { content: "\e883"; }
.#{$md-css-prefix}-folder:before { content: "\e2c7"; }
.#{$md-css-prefix}-folder-open:before { content: "\e2c8"; }
.#{$md-css-prefix}-folder-shared:before { content: "\e2c9"; }
.#{$md-css-prefix}-folder-special:before { content: "\e617"; }
.#{$md-css-prefix}-font-download:before { content: "\e167"; }
.#{$md-css-prefix}-format-align-center:before { content: "\e234"; }
.#{$md-css-prefix}-format-align-justify:before { content: "\e235"; }
.#{$md-css-prefix}-format-align-left:before { content: "\e236"; }
.#{$md-css-prefix}-format-align-right:before { content: "\e237"; }
.#{$md-css-prefix}-format-bold:before { content: "\e238"; }
.#{$md-css-prefix}-format-clear:before { content: "\e239"; }
.#{$md-css-prefix}-format-color-fill:before { content: "\e23a"; }
.#{$md-css-prefix}-format-color-reset:before { content: "\e23b"; }
.#{$md-css-prefix}-format-color-text:before { content: "\e23c"; }
.#{$md-css-prefix}-format-indent-decrease:before { content: "\e23d"; }
.#{$md-css-prefix}-format-indent-increase:before { content: "\e23e"; }
.#{$md-css-prefix}-format-italic:before { content: "\e23f"; }
.#{$md-css-prefix}-format-line-spacing:before { content: "\e240"; }
.#{$md-css-prefix}-format-list-bulleted:before { content: "\e241"; }
.#{$md-css-prefix}-format-list-numbered:before { content: "\e242"; }
.#{$md-css-prefix}-format-paint:before { content: "\e243"; }
.#{$md-css-prefix}-format-quote:before { content: "\e244"; }
.#{$md-css-prefix}-format-shapes:before { content: "\e25e"; }
.#{$md-css-prefix}-format-size:before { content: "\e245"; }
.#{$md-css-prefix}-format-strikethrough:before { content: "\e246"; }
.#{$md-css-prefix}-format-textdirection-l-to-r:before { content: "\e247"; }
.#{$md-css-prefix}-format-textdirection-r-to-l:before { content: "\e248"; }
.#{$md-css-prefix}-format-underlined:before { content: "\e249"; }
.#{$md-css-prefix}-forum:before { content: "\e0bf"; }
.#{$md-css-prefix}-forward:before { content: "\e154"; }
.#{$md-css-prefix}-forward-10:before { content: "\e056"; }
.#{$md-css-prefix}-forward-30:before { content: "\e057"; }
.#{$md-css-prefix}-forward-5:before { content: "\e058"; }
.#{$md-css-prefix}-free-breakfast:before { content: "\eb44"; }
.#{$md-css-prefix}-fullscreen:before { content: "\e5d0"; }
.#{$md-css-prefix}-fullscreen-exit:before { content: "\e5d1"; }
.#{$md-css-prefix}-functions:before { content: "\e24a"; }
.#{$md-css-prefix}-g-translate:before { content: "\e927"; }
.#{$md-css-prefix}-gamepad:before { content: "\e30f"; }
.#{$md-css-prefix}-games:before { content: "\e021"; }
.#{$md-css-prefix}-gavel:before { content: "\e90e"; }
.#{$md-css-prefix}-gesture:before { content: "\e155"; }
.#{$md-css-prefix}-get-app:before { content: "\e884"; }
.#{$md-css-prefix}-gif:before { content: "\e908"; }
.#{$md-css-prefix}-golf-course:before { content: "\eb45"; }
.#{$md-css-prefix}-gps-fixed:before { content: "\e1b3"; }
.#{$md-css-prefix}-gps-not-fixed:before { content: "\e1b4"; }
.#{$md-css-prefix}-gps-off:before { content: "\e1b5"; }
.#{$md-css-prefix}-grade:before { content: "\e885"; }
.#{$md-css-prefix}-gradient:before { content: "\e3e9"; }
.#{$md-css-prefix}-grain:before { content: "\e3ea"; }
.#{$md-css-prefix}-graphic-eq:before { content: "\e1b8"; }
.#{$md-css-prefix}-grid-off:before { content: "\e3eb"; }
.#{$md-css-prefix}-grid-on:before { content: "\e3ec"; }
.#{$md-css-prefix}-group:before { content: "\e7ef"; }
.#{$md-css-prefix}-group-add:before { content: "\e7f0"; }
.#{$md-css-prefix}-group-work:before { content: "\e886"; }
.#{$md-css-prefix}-hd:before { content: "\e052"; }
.#{$md-css-prefix}-hdr-off:before { content: "\e3ed"; }
.#{$md-css-prefix}-hdr-on:before { content: "\e3ee"; }
.#{$md-css-prefix}-hdr-strong:before { content: "\e3f1"; }
.#{$md-css-prefix}-hdr-weak:before { content: "\e3f2"; }
.#{$md-css-prefix}-headset:before { content: "\e310"; }
.#{$md-css-prefix}-headset-mic:before { content: "\e311"; }
.#{$md-css-prefix}-healing:before { content: "\e3f3"; }
.#{$md-css-prefix}-hearing:before { content: "\e023"; }
.#{$md-css-prefix}-help:before { content: "\e887"; }
.#{$md-css-prefix}-help-outline:before { content: "\e8fd"; }
.#{$md-css-prefix}-high-quality:before { content: "\e024"; }
.#{$md-css-prefix}-highlight:before { content: "\e25f"; }
.#{$md-css-prefix}-highlight-off:before { content: "\e888"; }
.#{$md-css-prefix}-history:before { content: "\e889"; }
.#{$md-css-prefix}-home:before { content: "\e88a"; }
.#{$md-css-prefix}-hot-tub:before { content: "\eb46"; }
.#{$md-css-prefix}-hotel:before { content: "\e53a"; }
.#{$md-css-prefix}-hourglass-empty:before { content: "\e88b"; }
.#{$md-css-prefix}-hourglass-full:before { content: "\e88c"; }
.#{$md-css-prefix}-http:before { content: "\e902"; }
.#{$md-css-prefix}-https:before { content: "\e88d"; }
.#{$md-css-prefix}-image:before { content: "\e3f4"; }
.#{$md-css-prefix}-image-aspect-ratio:before { content: "\e3f5"; }
.#{$md-css-prefix}-import-contacts:before { content: "\e0e0"; }
.#{$md-css-prefix}-import-export:before { content: "\e0c3"; }
.#{$md-css-prefix}-important-devices:before { content: "\e912"; }
.#{$md-css-prefix}-inbox:before { content: "\e156"; }
.#{$md-css-prefix}-indeterminate-check-box:before { content: "\e909"; }
.#{$md-css-prefix}-info:before { content: "\e88e"; }
.#{$md-css-prefix}-info-outline:before { content: "\e88f"; }
.#{$md-css-prefix}-input:before { content: "\e890"; }
.#{$md-css-prefix}-insert-chart:before { content: "\e24b"; }
.#{$md-css-prefix}-insert-comment:before { content: "\e24c"; }
.#{$md-css-prefix}-insert-drive-file:before { content: "\e24d"; }
.#{$md-css-prefix}-insert-emoticon:before { content: "\e24e"; }
.#{$md-css-prefix}-insert-invitation:before { content: "\e24f"; }
.#{$md-css-prefix}-insert-link:before { content: "\e250"; }
.#{$md-css-prefix}-insert-photo:before { content: "\e251"; }
.#{$md-css-prefix}-invert-colors:before { content: "\e891"; }
.#{$md-css-prefix}-invert-colors-off:before { content: "\e0c4"; }
.#{$md-css-prefix}-iso:before { content: "\e3f6"; }
.#{$md-css-prefix}-keyboard:before { content: "\e312"; }
.#{$md-css-prefix}-keyboard-arrow-down:before { content: "\e313"; }
.#{$md-css-prefix}-keyboard-arrow-left:before { content: "\e314"; }
.#{$md-css-prefix}-keyboard-arrow-right:before { content: "\e315"; }
.#{$md-css-prefix}-keyboard-arrow-up:before { content: "\e316"; }
.#{$md-css-prefix}-keyboard-backspace:before { content: "\e317"; }
.#{$md-css-prefix}-keyboard-capslock:before { content: "\e318"; }
.#{$md-css-prefix}-keyboard-hide:before { content: "\e31a"; }
.#{$md-css-prefix}-keyboard-return:before { content: "\e31b"; }
.#{$md-css-prefix}-keyboard-tab:before { content: "\e31c"; }
.#{$md-css-prefix}-keyboard-voice:before { content: "\e31d"; }
.#{$md-css-prefix}-kitchen:before { content: "\eb47"; }
.#{$md-css-prefix}-label:before { content: "\e892"; }
.#{$md-css-prefix}-label-outline:before { content: "\e893"; }
.#{$md-css-prefix}-landscape:before { content: "\e3f7"; }
.#{$md-css-prefix}-language:before { content: "\e894"; }
.#{$md-css-prefix}-laptop:before { content: "\e31e"; }
.#{$md-css-prefix}-laptop-chromebook:before { content: "\e31f"; }
.#{$md-css-prefix}-laptop-mac:before { content: "\e320"; }
.#{$md-css-prefix}-laptop-windows:before { content: "\e321"; }
.#{$md-css-prefix}-last-page:before { content: "\e5dd"; }
.#{$md-css-prefix}-launch:before { content: "\e895"; }
.#{$md-css-prefix}-layers:before { content: "\e53b"; }
.#{$md-css-prefix}-layers-clear:before { content: "\e53c"; }
.#{$md-css-prefix}-leak-add:before { content: "\e3f8"; }
.#{$md-css-prefix}-leak-remove:before { content: "\e3f9"; }
.#{$md-css-prefix}-lens:before { content: "\e3fa"; }
.#{$md-css-prefix}-library-add:before { content: "\e02e"; }
.#{$md-css-prefix}-library-books:before { content: "\e02f"; }
.#{$md-css-prefix}-library-music:before { content: "\e030"; }
.#{$md-css-prefix}-lightbulb-outline:before { content: "\e90f"; }
.#{$md-css-prefix}-line-style:before { content: "\e919"; }
.#{$md-css-prefix}-line-weight:before { content: "\e91a"; }
.#{$md-css-prefix}-linear-scale:before { content: "\e260"; }
.#{$md-css-prefix}-link:before { content: "\e157"; }
.#{$md-css-prefix}-linked-camera:before { content: "\e438"; }
.#{$md-css-prefix}-list:before { content: "\e896"; }
.#{$md-css-prefix}-live-help:before { content: "\e0c6"; }
.#{$md-css-prefix}-live-tv:before { content: "\e639"; }
.#{$md-css-prefix}-local-activity:before { content: "\e53f"; }
.#{$md-css-prefix}-local-airport:before { content: "\e53d"; }
.#{$md-css-prefix}-local-atm:before { content: "\e53e"; }
.#{$md-css-prefix}-local-bar:before { content: "\e540"; }
.#{$md-css-prefix}-local-cafe:before { content: "\e541"; }
.#{$md-css-prefix}-local-car-wash:before { content: "\e542"; }
.#{$md-css-prefix}-local-convenience-store:before { content: "\e543"; }
.#{$md-css-prefix}-local-dining:before { content: "\e556"; }
.#{$md-css-prefix}-local-drink:before { content: "\e544"; }
.#{$md-css-prefix}-local-florist:before { content: "\e545"; }
.#{$md-css-prefix}-local-gas-station:before { content: "\e546"; }
.#{$md-css-prefix}-local-grocery-store:before { content: "\e547"; }
.#{$md-css-prefix}-local-hospital:before { content: "\e548"; }
.#{$md-css-prefix}-local-hotel:before { content: "\e549"; }
.#{$md-css-prefix}-local-laundry-service:before { content: "\e54a"; }
.#{$md-css-prefix}-local-library:before { content: "\e54b"; }
.#{$md-css-prefix}-local-mall:before { content: "\e54c"; }
.#{$md-css-prefix}-local-movies:before { content: "\e54d"; }
.#{$md-css-prefix}-local-offer:before { content: "\e54e"; }
.#{$md-css-prefix}-local-parking:before { content: "\e54f"; }
.#{$md-css-prefix}-local-pharmacy:before { content: "\e550"; }
.#{$md-css-prefix}-local-phone:before { content: "\e551"; }
.#{$md-css-prefix}-local-pizza:before { content: "\e552"; }
.#{$md-css-prefix}-local-play:before { content: "\e553"; }
.#{$md-css-prefix}-local-post-office:before { content: "\e554"; }
.#{$md-css-prefix}-local-printshop:before { content: "\e555"; }
.#{$md-css-prefix}-local-see:before { content: "\e557"; }
.#{$md-css-prefix}-local-shipping:before { content: "\e558"; }
.#{$md-css-prefix}-local-taxi:before { content: "\e559"; }
.#{$md-css-prefix}-location-city:before { content: "\e7f1"; }
.#{$md-css-prefix}-location-disabled:before { content: "\e1b6"; }
.#{$md-css-prefix}-location-off:before { content: "\e0c7"; }
.#{$md-css-prefix}-location-on:before { content: "\e0c8"; }
.#{$md-css-prefix}-location-searching:before { content: "\e1b7"; }
.#{$md-css-prefix}-lock:before { content: "\e897"; }
.#{$md-css-prefix}-lock-open:before { content: "\e898"; }
.#{$md-css-prefix}-lock-outline:before { content: "\e899"; }
.#{$md-css-prefix}-looks:before { content: "\e3fc"; }
.#{$md-css-prefix}-looks-3:before { content: "\e3fb"; }
.#{$md-css-prefix}-looks-4:before { content: "\e3fd"; }
.#{$md-css-prefix}-looks-5:before { content: "\e3fe"; }
.#{$md-css-prefix}-looks-6:before { content: "\e3ff"; }
.#{$md-css-prefix}-looks-one:before { content: "\e400"; }
.#{$md-css-prefix}-looks-two:before { content: "\e401"; }
.#{$md-css-prefix}-loop:before { content: "\e028"; }
.#{$md-css-prefix}-loupe:before { content: "\e402"; }
.#{$md-css-prefix}-low-priority:before { content: "\e16d"; }
.#{$md-css-prefix}-loyalty:before { content: "\e89a"; }
.#{$md-css-prefix}-mail:before { content: "\e158"; }
.#{$md-css-prefix}-mail-outline:before { content: "\e0e1"; }
.#{$md-css-prefix}-map:before { content: "\e55b"; }
.#{$md-css-prefix}-markunread:before { content: "\e159"; }
.#{$md-css-prefix}-markunread-mailbox:before { content: "\e89b"; }
.#{$md-css-prefix}-memory:before { content: "\e322"; }
.#{$md-css-prefix}-menu:before { content: "\e5d2"; }
.#{$md-css-prefix}-merge-type:before { content: "\e252"; }
.#{$md-css-prefix}-message:before { content: "\e0c9"; }
.#{$md-css-prefix}-mic:before { content: "\e029"; }
.#{$md-css-prefix}-mic-none:before { content: "\e02a"; }
.#{$md-css-prefix}-mic-off:before { content: "\e02b"; }
.#{$md-css-prefix}-mms:before { content: "\e618"; }
.#{$md-css-prefix}-mode-comment:before { content: "\e253"; }
.#{$md-css-prefix}-mode-edit:before { content: "\e254"; }
.#{$md-css-prefix}-monetization-on:before { content: "\e263"; }
.#{$md-css-prefix}-money-off:before { content: "\e25c"; }
.#{$md-css-prefix}-monochrome-photos:before { content: "\e403"; }
.#{$md-css-prefix}-mood:before { content: "\e7f2"; }
.#{$md-css-prefix}-mood-bad:before { content: "\e7f3"; }
.#{$md-css-prefix}-more:before { content: "\e619"; }
.#{$md-css-prefix}-more-horiz:before { content: "\e5d3"; }
.#{$md-css-prefix}-more-vert:before { content: "\e5d4"; }
.#{$md-css-prefix}-motorcycle:before { content: "\e91b"; }
.#{$md-css-prefix}-mouse:before { content: "\e323"; }
.#{$md-css-prefix}-move-to-inbox:before { content: "\e168"; }
.#{$md-css-prefix}-movie:before { content: "\e02c"; }
.#{$md-css-prefix}-movie-creation:before { content: "\e404"; }
.#{$md-css-prefix}-movie-filter:before { content: "\e43a"; }
.#{$md-css-prefix}-multiline-chart:before { content: "\e6df"; }
.#{$md-css-prefix}-music-note:before { content: "\e405"; }
.#{$md-css-prefix}-music-video:before { content: "\e063"; }
.#{$md-css-prefix}-my-location:before { content: "\e55c"; }
.#{$md-css-prefix}-nature:before { content: "\e406"; }
.#{$md-css-prefix}-nature-people:before { content: "\e407"; }
.#{$md-css-prefix}-navigate-before:before { content: "\e408"; }
.#{$md-css-prefix}-navigate-next:before { content: "\e409"; }
.#{$md-css-prefix}-navigation:before { content: "\e55d"; }
.#{$md-css-prefix}-near-me:before { content: "\e569"; }
.#{$md-css-prefix}-network-cell:before { content: "\e1b9"; }
.#{$md-css-prefix}-network-check:before { content: "\e640"; }
.#{$md-css-prefix}-network-locked:before { content: "\e61a"; }
.#{$md-css-prefix}-network-wifi:before { content: "\e1ba"; }
.#{$md-css-prefix}-new-releases:before { content: "\e031"; }
.#{$md-css-prefix}-next-week:before { content: "\e16a"; }
.#{$md-css-prefix}-nfc:before { content: "\e1bb"; }
.#{$md-css-prefix}-no-encryption:before { content: "\e641"; }
.#{$md-css-prefix}-no-sim:before { content: "\e0cc"; }
.#{$md-css-prefix}-not-interested:before { content: "\e033"; }
.#{$md-css-prefix}-note:before { content: "\e06f"; }
.#{$md-css-prefix}-note-add:before { content: "\e89c"; }
.#{$md-css-prefix}-notifications:before { content: "\e7f4"; }
.#{$md-css-prefix}-notifications-active:before { content: "\e7f7"; }
.#{$md-css-prefix}-notifications-none:before { content: "\e7f5"; }
.#{$md-css-prefix}-notifications-off:before { content: "\e7f6"; }
.#{$md-css-prefix}-notifications-paused:before { content: "\e7f8"; }
.#{$md-css-prefix}-offline-pin:before { content: "\e90a"; }
.#{$md-css-prefix}-ondemand-video:before { content: "\e63a"; }
.#{$md-css-prefix}-opacity:before { content: "\e91c"; }
.#{$md-css-prefix}-open-in-browser:before { content: "\e89d"; }
.#{$md-css-prefix}-open-in-new:before { content: "\e89e"; }
.#{$md-css-prefix}-open-with:before { content: "\e89f"; }
.#{$md-css-prefix}-pages:before { content: "\e7f9"; }
.#{$md-css-prefix}-pageview:before { content: "\e8a0"; }
.#{$md-css-prefix}-palette:before { content: "\e40a"; }
.#{$md-css-prefix}-pan-tool:before { content: "\e925"; }
.#{$md-css-prefix}-panorama:before { content: "\e40b"; }
.#{$md-css-prefix}-panorama-fish-eye:before { content: "\e40c"; }
.#{$md-css-prefix}-panorama-horizontal:before { content: "\e40d"; }
.#{$md-css-prefix}-panorama-vertical:before { content: "\e40e"; }
.#{$md-css-prefix}-panorama-wide-angle:before { content: "\e40f"; }
.#{$md-css-prefix}-party-mode:before { content: "\e7fa"; }
.#{$md-css-prefix}-pause:before { content: "\e034"; }
.#{$md-css-prefix}-pause-circle-filled:before { content: "\e035"; }
.#{$md-css-prefix}-pause-circle-outline:before { content: "\e036"; }
.#{$md-css-prefix}-payment:before { content: "\e8a1"; }
.#{$md-css-prefix}-people:before { content: "\e7fb"; }
.#{$md-css-prefix}-people-outline:before { content: "\e7fc"; }
.#{$md-css-prefix}-perm-camera-mic:before { content: "\e8a2"; }
.#{$md-css-prefix}-perm-contact-calendar:before { content: "\e8a3"; }
.#{$md-css-prefix}-perm-data-setting:before { content: "\e8a4"; }
.#{$md-css-prefix}-perm-device-information:before { content: "\e8a5"; }
.#{$md-css-prefix}-perm-identity:before { content: "\e8a6"; }
.#{$md-css-prefix}-perm-media:before { content: "\e8a7"; }
.#{$md-css-prefix}-perm-phone-msg:before { content: "\e8a8"; }
.#{$md-css-prefix}-perm-scan-wifi:before { content: "\e8a9"; }
.#{$md-css-prefix}-person:before { content: "\e7fd"; }
.#{$md-css-prefix}-person-add:before { content: "\e7fe"; }
.#{$md-css-prefix}-person-outline:before { content: "\e7ff"; }
.#{$md-css-prefix}-person-pin:before { content: "\e55a"; }
.#{$md-css-prefix}-person-pin-circle:before { content: "\e56a"; }
.#{$md-css-prefix}-personal-video:before { content: "\e63b"; }
.#{$md-css-prefix}-pets:before { content: "\e91d"; }
.#{$md-css-prefix}-phone:before { content: "\e0cd"; }
.#{$md-css-prefix}-phone-android:before { content: "\e324"; }
.#{$md-css-prefix}-phone-bluetooth-speaker:before { content: "\e61b"; }
.#{$md-css-prefix}-phone-forwarded:before { content: "\e61c"; }
.#{$md-css-prefix}-phone-in-talk:before { content: "\e61d"; }
.#{$md-css-prefix}-phone-iphone:before { content: "\e325"; }
.#{$md-css-prefix}-phone-locked:before { content: "\e61e"; }
.#{$md-css-prefix}-phone-missed:before { content: "\e61f"; }
.#{$md-css-prefix}-phone-paused:before { content: "\e620"; }
.#{$md-css-prefix}-phonelink:before { content: "\e326"; }
.#{$md-css-prefix}-phonelink-erase:before { content: "\e0db"; }
.#{$md-css-prefix}-phonelink-lock:before { content: "\e0dc"; }
.#{$md-css-prefix}-phonelink-off:before { content: "\e327"; }
.#{$md-css-prefix}-phonelink-ring:before { content: "\e0dd"; }
.#{$md-css-prefix}-phonelink-setup:before { content: "\e0de"; }
.#{$md-css-prefix}-photo:before { content: "\e410"; }
.#{$md-css-prefix}-photo-album:before { content: "\e411"; }
.#{$md-css-prefix}-photo-camera:before { content: "\e412"; }
.#{$md-css-prefix}-photo-filter:before { content: "\e43b"; }
.#{$md-css-prefix}-photo-library:before { content: "\e413"; }
.#{$md-css-prefix}-photo-size-select-actual:before { content: "\e432"; }
.#{$md-css-prefix}-photo-size-select-large:before { content: "\e433"; }
.#{$md-css-prefix}-photo-size-select-small:before { content: "\e434"; }
.#{$md-css-prefix}-picture-as-pdf:before { content: "\e415"; }
.#{$md-css-prefix}-picture-in-picture:before { content: "\e8aa"; }
.#{$md-css-prefix}-picture-in-picture-alt:before { content: "\e911"; }
.#{$md-css-prefix}-pie-chart:before { content: "\e6c4"; }
.#{$md-css-prefix}-pie-chart-outlined:before { content: "\e6c5"; }
.#{$md-css-prefix}-pin-drop:before { content: "\e55e"; }
.#{$md-css-prefix}-place:before { content: "\e55f"; }
.#{$md-css-prefix}-play-arrow:before { content: "\e037"; }
.#{$md-css-prefix}-play-circle-filled:before { content: "\e038"; }
.#{$md-css-prefix}-play-circle-outline:before { content: "\e039"; }
.#{$md-css-prefix}-play-for-work:before { content: "\e906"; }
.#{$md-css-prefix}-playlist-add:before { content: "\e03b"; }
.#{$md-css-prefix}-playlist-add-check:before { content: "\e065"; }
.#{$md-css-prefix}-playlist-play:before { content: "\e05f"; }
.#{$md-css-prefix}-plus-one:before { content: "\e800"; }
.#{$md-css-prefix}-poll:before { content: "\e801"; }
.#{$md-css-prefix}-polymer:before { content: "\e8ab"; }
.#{$md-css-prefix}-pool:before { content: "\eb48"; }
.#{$md-css-prefix}-portable-wifi-off:before { content: "\e0ce"; }
.#{$md-css-prefix}-portrait:before { content: "\e416"; }
.#{$md-css-prefix}-power:before { content: "\e63c"; }
.#{$md-css-prefix}-power-input:before { content: "\e336"; }
.#{$md-css-prefix}-power-settings-new:before { content: "\e8ac"; }
.#{$md-css-prefix}-pregnant-woman:before { content: "\e91e"; }
.#{$md-css-prefix}-present-to-all:before { content: "\e0df"; }
.#{$md-css-prefix}-print:before { content: "\e8ad"; }
.#{$md-css-prefix}-priority-high:before { content: "\e645"; }
.#{$md-css-prefix}-public:before { content: "\e80b"; }
.#{$md-css-prefix}-publish:before { content: "\e255"; }
.#{$md-css-prefix}-query-builder:before { content: "\e8ae"; }
.#{$md-css-prefix}-question-answer:before { content: "\e8af"; }
.#{$md-css-prefix}-queue:before { content: "\e03c"; }
.#{$md-css-prefix}-queue-music:before { content: "\e03d"; }
.#{$md-css-prefix}-queue-play-next:before { content: "\e066"; }
.#{$md-css-prefix}-radio:before { content: "\e03e"; }
.#{$md-css-prefix}-radio-button-checked:before { content: "\e837"; }
.#{$md-css-prefix}-radio-button-unchecked:before { content: "\e836"; }
.#{$md-css-prefix}-rate-review:before { content: "\e560"; }
.#{$md-css-prefix}-receipt:before { content: "\e8b0"; }
.#{$md-css-prefix}-recent-actors:before { content: "\e03f"; }
.#{$md-css-prefix}-record-voice-over:before { content: "\e91f"; }
.#{$md-css-prefix}-redeem:before { content: "\e8b1"; }
.#{$md-css-prefix}-redo:before { content: "\e15a"; }
.#{$md-css-prefix}-refresh:before { content: "\e5d5"; }
.#{$md-css-prefix}-remove:before { content: "\e15b"; }
.#{$md-css-prefix}-remove-circle:before { content: "\e15c"; }
.#{$md-css-prefix}-remove-circle-outline:before { content: "\e15d"; }
.#{$md-css-prefix}-remove-from-queue:before { content: "\e067"; }
.#{$md-css-prefix}-remove-red-eye:before { content: "\e417"; }
.#{$md-css-prefix}-remove-shopping-cart:before { content: "\e928"; }
.#{$md-css-prefix}-reorder:before { content: "\e8fe"; }
.#{$md-css-prefix}-repeat:before { content: "\e040"; }
.#{$md-css-prefix}-repeat-one:before { content: "\e041"; }
.#{$md-css-prefix}-replay:before { content: "\e042"; }
.#{$md-css-prefix}-replay-10:before { content: "\e059"; }
.#{$md-css-prefix}-replay-30:before { content: "\e05a"; }
.#{$md-css-prefix}-replay-5:before { content: "\e05b"; }
.#{$md-css-prefix}-reply:before { content: "\e15e"; }
.#{$md-css-prefix}-reply-all:before { content: "\e15f"; }
.#{$md-css-prefix}-report:before { content: "\e160"; }
.#{$md-css-prefix}-report-problem:before { content: "\e8b2"; }
.#{$md-css-prefix}-restaurant:before { content: "\e56c"; }
.#{$md-css-prefix}-restaurant-menu:before { content: "\e561"; }
.#{$md-css-prefix}-restore:before { content: "\e8b3"; }
.#{$md-css-prefix}-restore-page:before { content: "\e929"; }
.#{$md-css-prefix}-ring-volume:before { content: "\e0d1"; }
.#{$md-css-prefix}-room:before { content: "\e8b4"; }
.#{$md-css-prefix}-room-service:before { content: "\eb49"; }
.#{$md-css-prefix}-rotate-90-degrees-ccw:before { content: "\e418"; }
.#{$md-css-prefix}-rotate-left:before { content: "\e419"; }
.#{$md-css-prefix}-rotate-right:before { content: "\e41a"; }
.#{$md-css-prefix}-rounded-corner:before { content: "\e920"; }
.#{$md-css-prefix}-router:before { content: "\e328"; }
.#{$md-css-prefix}-rowing:before { content: "\e921"; }
.#{$md-css-prefix}-rss-feed:before { content: "\e0e5"; }
.#{$md-css-prefix}-rv-hookup:before { content: "\e642"; }
.#{$md-css-prefix}-satellite:before { content: "\e562"; }
.#{$md-css-prefix}-save:before { content: "\e161"; }
.#{$md-css-prefix}-scanner:before { content: "\e329"; }
.#{$md-css-prefix}-schedule:before { content: "\e8b5"; }
.#{$md-css-prefix}-school:before { content: "\e80c"; }
.#{$md-css-prefix}-screen-lock-landscape:before { content: "\e1be"; }
.#{$md-css-prefix}-screen-lock-portrait:before { content: "\e1bf"; }
.#{$md-css-prefix}-screen-lock-rotation:before { content: "\e1c0"; }
.#{$md-css-prefix}-screen-rotation:before { content: "\e1c1"; }
.#{$md-css-prefix}-screen-share:before { content: "\e0e2"; }
.#{$md-css-prefix}-sd-card:before { content: "\e623"; }
.#{$md-css-prefix}-sd-storage:before { content: "\e1c2"; }
.#{$md-css-prefix}-search:before { content: "\e8b6"; }
.#{$md-css-prefix}-security:before { content: "\e32a"; }
.#{$md-css-prefix}-select-all:before { content: "\e162"; }
.#{$md-css-prefix}-send:before { content: "\e163"; }
.#{$md-css-prefix}-sentiment-dissatisfied:before { content: "\e811"; }
.#{$md-css-prefix}-sentiment-neutral:before { content: "\e812"; }
.#{$md-css-prefix}-sentiment-satisfied:before { content: "\e813"; }
.#{$md-css-prefix}-sentiment-very-dissatisfied:before { content: "\e814"; }
.#{$md-css-prefix}-sentiment-very-satisfied:before { content: "\e815"; }
.#{$md-css-prefix}-settings:before { content: "\e8b8"; }
.#{$md-css-prefix}-settings-applications:before { content: "\e8b9"; }
.#{$md-css-prefix}-settings-backup-restore:before { content: "\e8ba"; }
.#{$md-css-prefix}-settings-bluetooth:before { content: "\e8bb"; }
.#{$md-css-prefix}-settings-brightness:before { content: "\e8bd"; }
.#{$md-css-prefix}-settings-cell:before { content: "\e8bc"; }
.#{$md-css-prefix}-settings-ethernet:before { content: "\e8be"; }
.#{$md-css-prefix}-settings-input-antenna:before { content: "\e8bf"; }
.#{$md-css-prefix}-settings-input-component:before { content: "\e8c0"; }
.#{$md-css-prefix}-settings-input-composite:before { content: "\e8c1"; }
.#{$md-css-prefix}-settings-input-hdmi:before { content: "\e8c2"; }
.#{$md-css-prefix}-settings-input-svideo:before { content: "\e8c3"; }
.#{$md-css-prefix}-settings-overscan:before { content: "\e8c4"; }
.#{$md-css-prefix}-settings-phone:before { content: "\e8c5"; }
.#{$md-css-prefix}-settings-power:before { content: "\e8c6"; }
.#{$md-css-prefix}-settings-remote:before { content: "\e8c7"; }
.#{$md-css-prefix}-settings-system-daydream:before { content: "\e1c3"; }
.#{$md-css-prefix}-settings-voice:before { content: "\e8c8"; }
.#{$md-css-prefix}-share:before { content: "\e80d"; }
.#{$md-css-prefix}-shop:before { content: "\e8c9"; }
.#{$md-css-prefix}-shop-two:before { content: "\e8ca"; }
.#{$md-css-prefix}-shopping-basket:before { content: "\e8cb"; }
.#{$md-css-prefix}-shopping-cart:before { content: "\e8cc"; }
.#{$md-css-prefix}-short-text:before { content: "\e261"; }
.#{$md-css-prefix}-show-chart:before { content: "\e6e1"; }
.#{$md-css-prefix}-shuffle:before { content: "\e043"; }
.#{$md-css-prefix}-signal-cellular-4-bar:before { content: "\e1c8"; }
.#{$md-css-prefix}-signal-cellular-connected-no-internet-4-bar:before { content: "\e1cd"; }
.#{$md-css-prefix}-signal-cellular-no-sim:before { content: "\e1ce"; }
.#{$md-css-prefix}-signal-cellular-null:before { content: "\e1cf"; }
.#{$md-css-prefix}-signal-cellular-off:before { content: "\e1d0"; }
.#{$md-css-prefix}-signal-wifi-4-bar:before { content: "\e1d8"; }
.#{$md-css-prefix}-signal-wifi-4-bar-lock:before { content: "\e1d9"; }
.#{$md-css-prefix}-signal-wifi-off:before { content: "\e1da"; }
.#{$md-css-prefix}-sim-card:before { content: "\e32b"; }
.#{$md-css-prefix}-sim-card-alert:before { content: "\e624"; }
.#{$md-css-prefix}-skip-next:before { content: "\e044"; }
.#{$md-css-prefix}-skip-previous:before { content: "\e045"; }
.#{$md-css-prefix}-slideshow:before { content: "\e41b"; }
.#{$md-css-prefix}-slow-motion-video:before { content: "\e068"; }
.#{$md-css-prefix}-smartphone:before { content: "\e32c"; }
.#{$md-css-prefix}-smoke-free:before { content: "\eb4a"; }
.#{$md-css-prefix}-smoking-rooms:before { content: "\eb4b"; }
.#{$md-css-prefix}-sms:before { content: "\e625"; }
.#{$md-css-prefix}-sms-failed:before { content: "\e626"; }
.#{$md-css-prefix}-snooze:before { content: "\e046"; }
.#{$md-css-prefix}-sort:before { content: "\e164"; }
.#{$md-css-prefix}-sort-by-alpha:before { content: "\e053"; }
.#{$md-css-prefix}-spa:before { content: "\eb4c"; }
.#{$md-css-prefix}-space-bar:before { content: "\e256"; }
.#{$md-css-prefix}-speaker:before { content: "\e32d"; }
.#{$md-css-prefix}-speaker-group:before { content: "\e32e"; }
.#{$md-css-prefix}-speaker-notes:before { content: "\e8cd"; }
.#{$md-css-prefix}-speaker-notes-off:before { content: "\e92a"; }
.#{$md-css-prefix}-speaker-phone:before { content: "\e0d2"; }
.#{$md-css-prefix}-spellcheck:before { content: "\e8ce"; }
.#{$md-css-prefix}-star:before { content: "\e838"; }
.#{$md-css-prefix}-star-border:before { content: "\e83a"; }
.#{$md-css-prefix}-star-half:before { content: "\e839"; }
.#{$md-css-prefix}-stars:before { content: "\e8d0"; }
.#{$md-css-prefix}-stay-current-landscape:before { content: "\e0d3"; }
.#{$md-css-prefix}-stay-current-portrait:before { content: "\e0d4"; }
.#{$md-css-prefix}-stay-primary-landscape:before { content: "\e0d5"; }
.#{$md-css-prefix}-stay-primary-portrait:before { content: "\e0d6"; }
.#{$md-css-prefix}-stop:before { content: "\e047"; }
.#{$md-css-prefix}-stop-screen-share:before { content: "\e0e3"; }
.#{$md-css-prefix}-storage:before { content: "\e1db"; }
.#{$md-css-prefix}-store:before { content: "\e8d1"; }
.#{$md-css-prefix}-store-mall-directory:before { content: "\e563"; }
.#{$md-css-prefix}-straighten:before { content: "\e41c"; }
.#{$md-css-prefix}-streetview:before { content: "\e56e"; }
.#{$md-css-prefix}-strikethrough-s:before { content: "\e257"; }
.#{$md-css-prefix}-style:before { content: "\e41d"; }
.#{$md-css-prefix}-subdirectory-arrow-left:before { content: "\e5d9"; }
.#{$md-css-prefix}-subdirectory-arrow-right:before { content: "\e5da"; }
.#{$md-css-prefix}-subject:before { content: "\e8d2"; }
.#{$md-css-prefix}-subscriptions:before { content: "\e064"; }
.#{$md-css-prefix}-subtitles:before { content: "\e048"; }
.#{$md-css-prefix}-subway:before { content: "\e56f"; }
.#{$md-css-prefix}-supervisor-account:before { content: "\e8d3"; }
.#{$md-css-prefix}-surround-sound:before { content: "\e049"; }
.#{$md-css-prefix}-swap-calls:before { content: "\e0d7"; }
.#{$md-css-prefix}-swap-horiz:before { content: "\e8d4"; }
.#{$md-css-prefix}-swap-vert:before { content: "\e8d5"; }
.#{$md-css-prefix}-swap-vertical-circle:before { content: "\e8d6"; }
.#{$md-css-prefix}-switch-camera:before { content: "\e41e"; }
.#{$md-css-prefix}-switch-video:before { content: "\e41f"; }
.#{$md-css-prefix}-sync:before { content: "\e627"; }
.#{$md-css-prefix}-sync-disabled:before { content: "\e628"; }
.#{$md-css-prefix}-sync-problem:before { content: "\e629"; }
.#{$md-css-prefix}-system-update:before { content: "\e62a"; }
.#{$md-css-prefix}-system-update-alt:before { content: "\e8d7"; }
.#{$md-css-prefix}-tab:before { content: "\e8d8"; }
.#{$md-css-prefix}-tab-unselected:before { content: "\e8d9"; }
.#{$md-css-prefix}-tablet:before { content: "\e32f"; }
.#{$md-css-prefix}-tablet-android:before { content: "\e330"; }
.#{$md-css-prefix}-tablet-mac:before { content: "\e331"; }
.#{$md-css-prefix}-tag-faces:before { content: "\e420"; }
.#{$md-css-prefix}-tap-and-play:before { content: "\e62b"; }
.#{$md-css-prefix}-terrain:before { content: "\e564"; }
.#{$md-css-prefix}-text-fields:before { content: "\e262"; }
.#{$md-css-prefix}-text-format:before { content: "\e165"; }
.#{$md-css-prefix}-textsms:before { content: "\e0d8"; }
.#{$md-css-prefix}-texture:before { content: "\e421"; }
.#{$md-css-prefix}-theaters:before { content: "\e8da"; }
.#{$md-css-prefix}-thumb-down:before { content: "\e8db"; }
.#{$md-css-prefix}-thumb-up:before { content: "\e8dc"; }
.#{$md-css-prefix}-thumbs-up-down:before { content: "\e8dd"; }
.#{$md-css-prefix}-time-to-leave:before { content: "\e62c"; }
.#{$md-css-prefix}-timelapse:before { content: "\e422"; }
.#{$md-css-prefix}-timeline:before { content: "\e922"; }
.#{$md-css-prefix}-timer:before { content: "\e425"; }
.#{$md-css-prefix}-timer-10:before { content: "\e423"; }
.#{$md-css-prefix}-timer-3:before { content: "\e424"; }
.#{$md-css-prefix}-timer-off:before { content: "\e426"; }
.#{$md-css-prefix}-title:before { content: "\e264"; }
.#{$md-css-prefix}-toc:before { content: "\e8de"; }
.#{$md-css-prefix}-today:before { content: "\e8df"; }
.#{$md-css-prefix}-toll:before { content: "\e8e0"; }
.#{$md-css-prefix}-tonality:before { content: "\e427"; }
.#{$md-css-prefix}-touch-app:before { content: "\e913"; }
.#{$md-css-prefix}-toys:before { content: "\e332"; }
.#{$md-css-prefix}-track-changes:before { content: "\e8e1"; }
.#{$md-css-prefix}-traffic:before { content: "\e565"; }
.#{$md-css-prefix}-train:before { content: "\e570"; }
.#{$md-css-prefix}-tram:before { content: "\e571"; }
.#{$md-css-prefix}-transfer-within-a-station:before { content: "\e572"; }
.#{$md-css-prefix}-transform:before { content: "\e428"; }
.#{$md-css-prefix}-translate:before { content: "\e8e2"; }
.#{$md-css-prefix}-trending-down:before { content: "\e8e3"; }
.#{$md-css-prefix}-trending-flat:before { content: "\e8e4"; }
.#{$md-css-prefix}-trending-up:before { content: "\e8e5"; }
.#{$md-css-prefix}-tune:before { content: "\e429"; }
.#{$md-css-prefix}-turned-in:before { content: "\e8e6"; }
.#{$md-css-prefix}-turned-in-not:before { content: "\e8e7"; }
.#{$md-css-prefix}-tv:before { content: "\e333"; }
.#{$md-css-prefix}-unarchive:before { content: "\e169"; }
.#{$md-css-prefix}-undo:before { content: "\e166"; }
.#{$md-css-prefix}-unfold-less:before { content: "\e5d6"; }
.#{$md-css-prefix}-unfold-more:before { content: "\e5d7"; }
.#{$md-css-prefix}-update:before { content: "\e923"; }
.#{$md-css-prefix}-usb:before { content: "\e1e0"; }
.#{$md-css-prefix}-verified-user:before { content: "\e8e8"; }
.#{$md-css-prefix}-vertical-align-bottom:before { content: "\e258"; }
.#{$md-css-prefix}-vertical-align-center:before { content: "\e259"; }
.#{$md-css-prefix}-vertical-align-top:before { content: "\e25a"; }
.#{$md-css-prefix}-vibration:before { content: "\e62d"; }
.#{$md-css-prefix}-video-call:before { content: "\e070"; }
.#{$md-css-prefix}-video-label:before { content: "\e071"; }
.#{$md-css-prefix}-video-library:before { content: "\e04a"; }
.#{$md-css-prefix}-videocam:before { content: "\e04b"; }
.#{$md-css-prefix}-videocam-off:before { content: "\e04c"; }
.#{$md-css-prefix}-videogame-asset:before { content: "\e338"; }
.#{$md-css-prefix}-view-agenda:before { content: "\e8e9"; }
.#{$md-css-prefix}-view-array:before { content: "\e8ea"; }
.#{$md-css-prefix}-view-carousel:before { content: "\e8eb"; }
.#{$md-css-prefix}-view-column:before { content: "\e8ec"; }
.#{$md-css-prefix}-view-comfy:before { content: "\e42a"; }
.#{$md-css-prefix}-view-compact:before { content: "\e42b"; }
.#{$md-css-prefix}-view-day:before { content: "\e8ed"; }
.#{$md-css-prefix}-view-headline:before { content: "\e8ee"; }
.#{$md-css-prefix}-view-list:before { content: "\e8ef"; }
.#{$md-css-prefix}-view-module:before { content: "\e8f0"; }
.#{$md-css-prefix}-view-quilt:before { content: "\e8f1"; }
.#{$md-css-prefix}-view-stream:before { content: "\e8f2"; }
.#{$md-css-prefix}-view-week:before { content: "\e8f3"; }
.#{$md-css-prefix}-vignette:before { content: "\e435"; }
.#{$md-css-prefix}-visibility:before { content: "\e8f4"; }
.#{$md-css-prefix}-visibility-off:before { content: "\e8f5"; }
.#{$md-css-prefix}-voice-chat:before { content: "\e62e"; }
.#{$md-css-prefix}-voicemail:before { content: "\e0d9"; }
.#{$md-css-prefix}-volume-down:before { content: "\e04d"; }
.#{$md-css-prefix}-volume-mute:before { content: "\e04e"; }
.#{$md-css-prefix}-volume-off:before { content: "\e04f"; }
.#{$md-css-prefix}-volume-up:before { content: "\e050"; }
.#{$md-css-prefix}-vpn-key:before { content: "\e0da"; }
.#{$md-css-prefix}-vpn-lock:before { content: "\e62f"; }
.#{$md-css-prefix}-wallpaper:before { content: "\e1bc"; }
.#{$md-css-prefix}-warning:before { content: "\e002"; }
.#{$md-css-prefix}-watch:before { content: "\e334"; }
.#{$md-css-prefix}-watch-later:before { content: "\e924"; }
.#{$md-css-prefix}-wb-auto:before { content: "\e42c"; }
.#{$md-css-prefix}-wb-cloudy:before { content: "\e42d"; }
.#{$md-css-prefix}-wb-incandescent:before { content: "\e42e"; }
.#{$md-css-prefix}-wb-iridescent:before { content: "\e436"; }
.#{$md-css-prefix}-wb-sunny:before { content: "\e430"; }
.#{$md-css-prefix}-wc:before { content: "\e63d"; }
.#{$md-css-prefix}-web:before { content: "\e051"; }
.#{$md-css-prefix}-web-asset:before { content: "\e069"; }
.#{$md-css-prefix}-weekend:before { content: "\e16b"; }
.#{$md-css-prefix}-whatshot:before { content: "\e80e"; }
.#{$md-css-prefix}-widgets:before { content: "\e1bd"; }
.#{$md-css-prefix}-wifi:before { content: "\e63e"; }
.#{$md-css-prefix}-wifi-lock:before { content: "\e1e1"; }
.#{$md-css-prefix}-wifi-tethering:before { content: "\e1e2"; }
.#{$md-css-prefix}-work:before { content: "\e8f9"; }
.#{$md-css-prefix}-wrap-text:before { content: "\e25b"; }
.#{$md-css-prefix}-youtube-searched-for:before { content: "\e8fa"; }
.#{$md-css-prefix}-zoom-in:before { content: "\e8ff"; }
.#{$md-css-prefix}-zoom-out:before { content: "\e900"; }
.#{$md-css-prefix}-zoom-out-map:before { content: "\e56b"; }
