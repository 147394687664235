.quanti_charts {
    width: 20%;
}

.chart {
    width: 100%;
    min-height: 400px;
}

ul {
    list-style-type: none;
}

.cssHeaderRow {
    background-color: #E239FF;
    text-align: center;
}

.cssTableRow {
    background-color: #F0F1F2;
}

.cssOddTableRow {
    background-color: #F0F1F2;
}

.cssSelectedTableRow {
    font-size: 16px;
}

.cssHoverTableRow {
    background: #ccc;
}

.cssHeaderCell {
    color: #FFFFFF;
    font-size: 16px;
    padding: 5px !important;
    border: solid 1px #000000;
}

.cssTableCell {
    font-size: 16px;
    padding: 5px !important;
    border: solid 1px #FFFFFF;
}

.cssRowNumberCell {
    text-align: center;
}

.cssHeaderRowLevel {
    background-color: #9c27b0;
    text-align: center;
}

/* Style pour : Les boutons */

.buttonLevel {
    -moz-box-shadow: 1px 1px 0px 0px #9C27B0;
    -webkit-box-shadow: 1px 1px 0px 0px #9C27B0;
    box-shadow: 1px 1px 0px 0px #9C27B0;
    background-color: #9C27B0;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: helvetica;
    font-size: 15px;
    padding: 7px 17px;
    margin-top: 1%;
    text-align: center;
    text-decoration: none;
}

.buttonLevel:hover {
    background-color: #9C27B0;
}

.buttonLevel:active {
    position: relative;
    top: 1px;
}

.buttonCrit {
    -moz-box-shadow: 1px 1px 0px 0px #E239FF;
    -webkit-box-shadow: 1px 1px 0px 0px #E239FF;
    box-shadow: 1px 1px 0px 0px #E239FF;
    background-color: #E239FF;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: helvetica;
    font-size: 15px;
    padding: 7px 17px;
    margin-top: 1%;
    text-align: center;
    text-decoration: none;
}

.buttonCrit:hover {
    background-color: #E239FF;
}

.buttonCrit:active {
    position: relative;
    top: 1px;
}

.select_options{
    display:none;
}

.div_choix_options{
    display:none;
}

.valider_ligne {
    display : none;
}

