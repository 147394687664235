.login .btn-link{
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}


.login h2 {
  margin-top: 0em;
  margin-bottom: 1em;
  color: $textColor;
}

.login .button {
  margin-bottom: 0em;
}
.login .password {
  margin-bottom: 0em;
}


.login .panel-footer{
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  text-align: center;
}
.login .captcha{
  margin-bottom: 1em;
}

.login .captcha .g-recaptcha > div > div {
  margin-left: auto;
  margin-right: auto;
}
